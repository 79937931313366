import React from "react";
import { Link } from "react-router-dom";
import ErrorImage from "../../assets/error.jpg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({ hasError: true, error });
  }

  handleReset = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center bg-white h-screen px-5">
          <img
            src={ErrorImage}
            alt="error-img"
            className="w-[80%] lg:w-[60%]"
          />
          <h1 className="font-medium lg:font-semibold leading-8 text-2xl lg:text-[28px] mb-3 lg:mb-6  text-[#080226]">
            {this.state.error && this.state.error.toString()}
          </h1>
          <Link
            to={"/"}
            onClick={this.handleReset}
            className="w-fit mx-auto px-5 lg:px-[23px] py-[6px] lg:py-2 rounded-lg bg-[#FFE5EE] text-[#E81E62] text-base lg:text-lg font-semibold leading-[22px] mb-3 lg:mb-[37px]"
          >
            Restablecer límite de error
          </Link>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
