import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  token: null,
  authLoading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = action.payload.isAuth;
      state.token = action.payload.token;
    },
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload;
    },
  },
});

// Export actions and selectors
export const { setAuth, setAuthLoading } = authSlice.actions;
export const selectIsAuth = (state) => state.auth.isAuth;
export const selectToken = (state) => state.auth.token;
export const selectAuthLoading = (state) => state.auth.authLoading;

// Export reducer
export default authSlice.reducer;
