// React imports...
import React from "react";

// MaterialUI component imports...
import { CircularProgress } from "@mui/material";

// React router imports...
import { Route, Routes } from "react-router-dom";

// React Redux imports...
import { useDispatch } from "react-redux";

// Redux Slice imports...
import { setAuth } from "./store/slices/authSlice";

// Lazy loading (Code Splitting for optimaization) page imports...
const Home = React.lazy(() => import("./pages/home"));
const SignIn = React.lazy(() => import("./pages/auth/signIn"));
const ForgotPassword = React.lazy(() => import("./pages/auth/ForgotPassword"));

const App = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(setAuth({ isAuth: true, token }));
    }
  }, [dispatch]);

  return (
    <React.Suspense
      fallback={
        <div className="w-full h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      }
    >
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/Signin" element={<SignIn />} />
        <Route path="reset-password" element={<ForgotPassword />} />
      </Routes>
    </React.Suspense>
  );
};

export default App;
